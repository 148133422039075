// WARNING: https://s.m3img.com/javascript/shared/jquery.mrkun.js を読み込んでいる必要がある %>
export default function showCustomizeArea() {
  (function ($) {
    $.fn.showCustomizeArea = function (from_page, displaySiteHash) {
      var displaySiteAndElementHash = {}
      for(let htmlId in displaySiteHash) {
        displaySiteAndElementHash[displaySiteHash[htmlId]] = $('#' + htmlId)
      }
      $mrkun.customizeAreaViewMultiEx(from_page, displaySiteAndElementHash)
    }
  })(jQuery)
}
