export default function footernavi() {
  window.onscroll = function () {
    var check = window.pageYOffset;
    var docHeight = $(document).height();
    var dispHeight = $(window).height();

    if(check > docHeight - dispHeight - 500) {
      $('.m3pcom-footernavi-container').fadeOut(1000);

    } else {
      $('.m3pcom-footernavi-container').fadeIn(1000);
    }
  };
}
