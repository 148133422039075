export default function dropup() {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      $(".m3pcom-footernavi-tag--wrap").css("display", "none");
      $(".m3pcom-footernavi-link-categoly").on("click", function () {
        $(".m3pcom-footernavi-tag--wrap").slideToggle(300, "linear");
      });
    });
  });
}
