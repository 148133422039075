export default function popover() {
  (function ($) {
    $.fn.setpopover = function () {

      var $dropdown = $('.js-popover');
      var DURATION = 100;

      function fadeOutMenu() {
        $dropdown.removeClass('is-active')
          .next('.js-popover-menu')
          .stop()
          .fadeOut(DURATION, "swing");
      }

      function toggleMenu() {
        var $self = $(this);
        if (!$self.hasClass('is-active')) {
          fadeOutMenu();
        }
        $self.toggleClass('is-active')
          .next('.js-popover-menu')
          .stop()
          .fadeToggle(DURATION, "swing");
      }

      $dropdown.off('click').on('click', toggleMenu);

      $(document).off('click touchend').on('click touchend', function (event) {
        if (!$(event.target).next('.js-popover-menu').length) {
          fadeOutMenu();
        }
      });
    };

    document.addEventListener("DOMContentLoaded", $.fn.setpopover);
  })(jQuery);
}
