export default function scroll() {
  (function ($) {
    let scrolling = false;
    $.fn.media = function () {
      var width = $(window).width();
      let headerHeightValue = (width <= 959 ? 60 : 0);
      $(function () {
        // ページ内リンクのOnClickにスムーススクロール処理を設定
        // ただし、ハンバーガーメニューのサブメニュー開閉のクラスは除外
        $('a[href^="#"]').not('.js-list-parent').off('click').click(function () {
          if (!scrolling) {
            scrolling = true;
            let headerHeight = headerHeightValue;
            let speed = 400;
            let type = 'swing';
            let href = $(this).attr("href");
            let target = $(href == "#index" ? 'html' : href);
            let position = target.offset().top - headerHeight;
            $('body,html').animate({ scrollTop: position }, speed, type, () => {scrolling = false;});
          }
          return false;
        });
      });
    };

    document.addEventListener("DOMContentLoaded", function () {

      // スムーススクロールヘッダ分
      $.fn.media();
      $(window).on("resize", function () { $.fn.media(); });

      // ヘルプページページ外遷移ヘッダ分
      $(window).on('load', function () {
        $(function () {
          let url = location.href;
          let headrPage = 60;
          let speedPage = 400;
          let typePage = 'swing';
          if (url.indexOf("?id=") != -1) {
            let id = url.split("?id=");
            let $target = $('#' + id[id.length - 1]);
            if ($target.length) {
              let position = $target.offset().top - headrPage;
              $("html, body").animate({ scrollTop: position }, speedPage, typePage);
            }
          }
        });
      })

      // ハンバーガーメニューのサブメニュー開閉のリンクを無効化（ページトップへの遷移を防止）
      $('a[href="#"]').filter('.js-list-parent').click( () => { return false; } );
    });

  })(jQuery);
}
