export default function good() {
  (function ($) {
    $.fn.good = function () {
      $(".good").off('click').click(function () {
        $(this).toggleClass("clicked");
      });
    };
    document.addEventListener("DOMContentLoaded", $.fn.good);
  })(jQuery);
}
