export default function adjust_tab_menu_scroll_position() {
  window.onload = () => {
    const currentTab = document.getElementsByClassName("js-adjustTabMenuSlidePosition m3p-column-tab--active")
    if(currentTab.length){
      adjustTabMenuSlidePosition(currentTab)
    }
  };

  const adjustTabMenuSlidePosition = (currentTab) => {
    const currentTabPosition = currentTab[0].offsetLeft;
    const tabElemWidth = currentTab[0].clientWidth
    const tabMenuWidth = currentTab[0].parentNode.clientWidth

    if(currentTabPosition > tabMenuWidth - tabElemWidth){
      const positionToCurrentTab = currentTabPosition - tabMenuWidth + tabElemWidth
      const adjustSlideNum = calcTabElemPadding(positionToCurrentTab)
      currentTab[0].parentNode.scrollLeft = positionToCurrentTab + adjustSlideNum
    }
  };

  // NOTE:
  // タブメニューが左にぴったり隠れている場合
  // タブメニューを左にスクロールできることがわかりづらいので、
  // チラ見せするために少しスクロール位置をズラす調整をする。
  const calcTabElemPadding = (positionToCurrentTab) => {
    const tabElems =  document.getElementsByClassName("js-adjustTabMenuSlidePosition")
    const tabElemEndPositions = [...tabElems].map(elem => {
      return elem.offsetLeft + elem.clientWidth
    })
    const tabElemPadding = $(tabElems[0].children[0]).css("padding-right")
    const tabElemPaddingNum = Number(tabElemPadding.split("p")[0])
    const justHideElem = tabElemEndPositions.filter(position => position < positionToCurrentTab + tabElemPaddingNum && position > positionToCurrentTab - tabElemPaddingNum)

    return justHideElem.length ? tabElemPaddingNum * 2 : 0
  };
}
