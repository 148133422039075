export default function accrdion() {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      $('.m3pcom-help-list--inner').css("display", "none");
      $('.m3pcom-js-help-btn').on('click', function () {
        $(this).next().slideToggle(300);
      })
    });

    document.querySelectorAll('[data-accordion-toggle-btn]').forEach((accordionBtn) => {
      accordionBtn.addEventListener('click', () => {
        const accordionName = accordionBtn.dataset.accordionToggleBtn;
        if(!!!accordionName) { return }

        const targetArea = document.querySelector(`[data-accordion-area="${accordionName}"]`)
        if(!!!targetArea) { return }

        targetArea.dataset.accordionStatus = 'show';
        accordionBtn.remove()
      });
    });
  });
}
