// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "./../channels"
import "jquery"
import popover from "./jsmodule/popover"
import footernavi from "./jsmodule/footernavi"
import accrdion from "./jsmodule/accrdion"
import adjust_tab_menu_scroll_position from "./jsmodule/adjust_tab_menu_scroll_position"
import dropup from "./jsmodule/dropup"
import good from "./jsmodule/good"
import modal from "./jsmodule/modal"
import scroll from "./jsmodule/scroll"
import toggle_display_float_header from "./jsmodule/toggle_display_float_header"
import postEopClickEvent from "./jsmodule/postEopClickEvent"
import viewmore from "./jsmodule/viewmore"
import comment_source_check from "./jsmodule/comment_source_check"
import showCustomizeArea from "./jsmodule/show_customize_area"

require.context('./images/', true)

require.context('./images/', true)

Rails.start()
ActiveStorage.start()

window.jQuery = jQuery;
window.$ = jQuery;

popover();
footernavi();
accrdion();
adjust_tab_menu_scroll_position();
dropup();
good();
modal();
scroll();
toggle_display_float_header();
postEopClickEvent()
viewmore();
comment_source_check();
showCustomizeArea();

// サイトアクセス時に特定のパラメータがついていたら、モーダルを起動する。
$(document).ready(function() {
  const url = new URL(window.location.href);
  const params = url.searchParams;

  const target_key = 'open-thread-modal'
  const is_open_modal = params.get(target_key)

  if (is_open_modal === 'true') {
    $('#navi_btn_thread').click()

    params.delete(target_key);
    history.replaceState('', '', url.toString());
  }
});

