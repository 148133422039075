export default function postEopClickEvent() {
  (function ($) {
    $.fn.setPostEopClickEvent = function () {
      if (!window.eop) {
        return
      }

      let targets = document.getElementsByClassName('eop-click')
      for (var target of targets) {
        target.addEventListener('click', postLog)
      }
      window.eop.markChildren(document, true);
      window.eop.scan();
    };
    document.addEventListener("DOMContentLoaded", $.fn.setPostEopClickEvent);
  })(jQuery)
}

function postLog() {
  const eopContents = this.dataset.eopContents;
  var eopEvent = $.extend({}, window.eop.getEnvironment(), {
    source: "web",
    action: "click",
    contents: eopContents,
    split: "default"
  });
  window.eop.postEvent([eopEvent], true);
}
