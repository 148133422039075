export default function comment_source_check() {
    (function ($) {
      $.fn.comment_source_check = function () {
        $('a[href^="#comment_div_"]').each(
          function(index, element){
            let anchor = $(element);
            let href = anchor.attr("href");
            if($(href).length){
              anchor.css('pointer-events', 'auto');
            }
            else {
              anchor.css('pointer-events', 'none');
            }
          }
        );
      };
    })(jQuery);
}
