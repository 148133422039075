export default function modal() {
  (function ($) {
    $.fn.setmodal = function () {
      $('.m3pcom-js-modal-open').off('click').click(function () {
        $('.m3pcom-js-modal-box').addClass("m3pcom-in");
        $('body').addClass("m3-twbs-modal-open");
        $('.m3pcom-js-modal-bg').fadeIn();

        var modal = '#' + $(this).attr('data-target');

        function modalResize() {
          var w = $(window).width();
          var h = $(window).height();

          var x = (w - $(modal).outerWidth(true)) / 2;
          var y = 300;

          $(modal).css({ 'left': x + 'px', 'top': y + 'px' });
        }

        modalResize();

        $(modal).fadeIn();

        $('.m3pcom-js-modal-bg, .m3pcom-js-modal-close, .m3pcom-btn--default').off().click(function () {
          $('.m3pcom-js-modal-box').removeClass("m3pcom-in");
          $('body').removeClass("m3-twbs-modal-open");
          $('.m3pcom-js-modal-box').fadeOut(function () {
            $('.m3pcom-js-modal-bg').fadeOut();
          });
        });

        $(window).off('resize').on('resize', function () {
          modalResize();
        });

        $('.m3pcom-js-modal-swipe').off('click').click(function () {

          $(this).parents('.m3pcom-js-modal-box').fadeOut();

          var modal = '#' + $(this).attr('data-target');

          function modalResize() {
            var w = $(window).width();
            var h = $(window).height();

            var x = (w - $(modal).outerWidth(true)) / 2;
            var y = 20;

            $(modal).css({ 'left': x + 'px', 'right': x + 'px', 'top': y + '%' });
          }

          modalResize();

          $(modal).fadeIn();

          $(window).off('resize').on('resize', function () {
            modalResize();
          });
        });
      });

      $('.modal-button').click(function () {
        // ブラウザバックを検知するために履歴を追加
        history.pushState(null,null,null);
      });

      window.addEventListener('popstate', function(e) {
        // ブラウザバック時の処理。薬剤師掲示板のモーダルを閉じる

        // PCOMMUNITY-572 が発生したため、その対応を入れる。
        // SP版メガメニュー表示時はブラウザバックの挙動を無効化する。
        // SPメガメニューのプルダウンメニュークリック時に、プルダウンがaタグであるためにURLが変更されるため、popstateイベントが発生してしまい、
        // sb-site要素内の要素をクリックが発生し、SPメガメニューがそのイベントをハンドルしてメガメニューを閉じてしまうため。
        if( $("#js-sp-container").find(".sb-slidebar").hasClass("sb-active") ){
          return
        }

        $(".m3pcom-modal").find('.icon-close-wrap').click();
      });
    };

    document.addEventListener("DOMContentLoaded", $.fn.setmodal);
  })(jQuery);
}
